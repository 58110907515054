import React from 'react'

const NewsletterOne = ({extraClass, newsletetrStyle}) => {
    return (
        <div className={`newsletter-area mb-20 ${newsletetrStyle}`}>
            <div className="container">
                <div className={`newsletter-wrapper ${extraClass}`}>
                    <div className="col-lg-12">
                        <div className="section-title text-center">
                            <h3 className="title">Subscribe Today</h3>
                            <p className="description">Subscribe to recive emails and promotions about our snippets, news & deals!</p>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <form className="rn-newsletter mt_md--20 mt_sm--20 text-center col-lg-4 m-auto"  action="#">
                            <div className="form-group">
                                <input type="email" placeholder="Email Address" />
                            </div>
                            <div className="form-group">
                                <button className="btn-default">Subscribe</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default NewsletterOne;
