import React from 'react';
import {Link} from "react-router-dom";
import SEO from "../common/SEO";
import AboutFive from '../elements/about/AboutFive';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import TestimonialOne from "../elements/testimonial/TestimonialOne";
import Separator from "../elements/separator/Separator";
import AccordionOne from "../elements/accordion/AccordionOne";
import TimelineTwo from '../elements/timeline/TimelineTwo';
import AdvanceTabOne from '../elements/advancetab/AdvanceTabOne';
import Layout from "../common/Layout";


const PersonalPortfolio = () => {
    return (
        <>
        <Layout>
            <SEO title="CodeFixd Provide Web Related Solution" />
            <main className="page-wrapper">

                {/* Start Slider Area  */}
                <div className="slider-perosonal-portfolio height-750 bg_image" data-black-overlay="1" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-11.png)`}}>
                    <div className="container">
                        <div className="row row--30 align-items-center">
                            <div className="col-12">
                                <div className="inner text-center">
                                    <h1 className="title">Hey! I'm <br /> <span className="theme-gradient">Ashraful Alam</span></h1>
                                    <div className="button-group mt--30">
                                    <Link className="btn-default round" to="/about-us"><span>ABOUT ME</span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <AboutFive/>
                <div id="services"></div>
                <Separator />
 
 {/* Start Elements Area  */}
 <div className="rwt-advance-tab-area rn-section-gap">
                        <div className="container">
                            <div className="row mb--40">
                                <div className="col-lg-8 offset-lg-2">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "What We Can Do For You"
                                        title = "Services provide for you."
                                        description = "I'll handle your technical requirements so you can stay focused on establishing the business. You can depend on me to create and maintain a solid infrastructure."
                                        
                                    />
                                </div>
                            </div>
                           <AdvanceTabOne/>
                        </div>
                    </div>
                    {/* End Elements Area  */}

<div style={{marginBottom: "60px"}} id="portfolio">
<Separator />
</div>
 
                {/* Start Portfolio Area  */}
                <div className="rwt-portfolio-area rn-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Project"
                                    title = "Check My Recent Works!"
                                    description = "We design and develop stunning websites to make your<br/> customers fall in love with your business."
                                />
                            </div>
                        </div>
                        <PortfolioOne Column="col-lg-4 col-md-6 col-12 mt--30 portfolio"  />
                    </div>
                </div>
                {/* End Portfolio Area  */}
                <div id="testimonial"></div>
                <Separator />
                {/* Start Elements Area  */}
                <div className="rwt-testimonial-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb--10">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Testimonial"
                                    title = "Awesome Clients Feedback."
                                    description = "We provide company and finance service for <br /> startups and company business."
                                />
                            </div>
                        </div>
                        <TestimonialOne column="col-lg-4 col-md-6 col-12 mt--30" teamStyle="card-style-default testimonial-style-one" />
                    </div>
                </div>
              {/* Start Accordion Area  */}
              <div id="faq"></div>
             
              <div className="rn-accordion-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 offset-lg-2">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "FAQ's"
                                        title = "FREQUENTLY ASKED QUESTIONS"
                                        description = "We are here to help to answer any and all questions you may have! If there is not a question answered here, let us in on the question and email it directly to codefixd@gmail.com"
                                    />
                                </div>
                            </div>
                            <div className="row mt--35 row--20">
                                <div className="col-lg-10 offset-lg-1">
                                    <AccordionOne customStyle="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Accordion Area  */}

{/* End Elements Area  */}
<div className="rwt-timeline-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 offset-lg-2">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            subtitle = "Timeline"
                                            title = "Working Process."
                                            description="My process, Graduate, is focused on your success. With a custom-tailored service for your Shopify business, I provide the tools and strategies you need for growth and success."
                                        />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 mt--50">
                                    <TimelineTwo classVar="no-gradient"  />
                                    
                                </div>
                            </div>
                        </div>
                    </div>



        
            </main>
            </Layout>
        </>
    )
}
export default PersonalPortfolio;