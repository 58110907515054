import React from "react";
import SEO from "../common/SEO";
import BrandOne from "../elements/brand/BrandOne";
import ProgressbarThree from "../elements/progressbar/ProgressbarThree";
import Typed from "react-typed";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import Layout from "../common/Layout";

const AboutUs = () => {
  return (
    <>
      <SEO title="About Us" />
      <Layout>
        <main className="page-wrapper">
          {/* Start Slider Area  */}
          <div className="slider-area slider-style-1 bg-transparent variation-2 height-750">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 order-2 order-lg-1 mt_md--40 mt_sm--40">
                  <div className="inner text-start">
                    <h1 className="title theme-gradient display-two">
                      Expert Freelancer <br /> For{" "}
                      <Typed
                        strings={[
                          "Web Development.",
                          "Graphic Design.",
                          "SEO.",
                        ]}
                        typeSpeed={80}
                        backSpeed={5}
                        backDelay={1000}
                        loop
                      />
                    </h1>
                    <p className="description">
                      Why settle for an average e-commerce website that fails to
                      generate sales? Collaborate with me and witness how I
                      enhance your online brand, amplify your conversion rates,
                      and grow your revenue. Join forces with me to develop a
                      website that truly revolutionizes the success of your
                      e-commerce business.
                    </p>
                    <div className="button-group">
                      <Link
                        className="btn-default btn-small btn-icon"
                        to="/contact"
                      >
                        Contact me{" "}
                        <i className="icon">
                          <FiArrowRight />
                        </i>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 order-1 order-lg-2">
                  <div className="thumbnail">
                    <img
                      src="./images/bg/programmer2.gif"
                      alt="Banner Images"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Slider Area  */}

          {/* Start Service Area  */}
          <div className="service-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="content">
                    <h3 className="title">
                    Why do you choose me over other freelancers?
                    </h3>
                    <p className="mb--10">
                    ※ I've solid experience creating websites from Scratch.<br/>
                    ※ I've solid experience in building any eCommerce website.<br/>
                    ※ I'll deliver the project on time.<br/>
                    ※ I'll provide you with quality work and Clean Code.<br/>
                    ※ The quality of my work helps maximize excellent ROI for my clients.<br/>
                    ※ Daily Status Report & Immediate response<br/>
                    ※ Extra Support after Completion of the Project.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  {/* Start Elements Area  */}
                  <div className="rwt-elements-area">
                    <div className="container">
                      <div className="rbt-progress-style-1 enable-gradient-fill no-radius">
                        <ProgressbarThree />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Service Area  */}

          {/* Start Brand Area  */}
          <div className="rwt-brand-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 text-center">
                                    <h2 className="">Expertise</h2>
                                    <p>I have Professional Experience in the Following Platforms.</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 mt--40">
                                    <BrandOne brandStyle="brand-style-1" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Brand Area  */}

        </main>
      </Layout>
    </>
  );
};

export default AboutUs;
