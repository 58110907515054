import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ScrollAnimation from "react-animate-on-scroll";

const AdvanceTabOne = () => {
    return (
        <ScrollAnimation
        animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
        <div>
            <Tabs>
                <div className="row row--30">
                    <div className="order-2 order-lg-1 col-lg-5 col-md-12 col-sm-12 col-12 mt_md--30 mt_sm--30">
                        <div className="advance-tab-button advance-tab-button-1">
                            <TabList className="tab-button-list">
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">Web Development</h4>
                                        <p className="description">I build stunning Website for your business with 100% satisfaction, like WordPress, Shopify and CMS.</p>
                                    </div>   
                                </Tab>
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">Graphic Design</h4>
                                        <p className="description">I build any type of Graphic Designs, like Logo Design, Banner Design, Ecommerce Photo Editng</p>
                                    </div>   
                                </Tab>
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">SEO - Search Engine Optimization</h4>
                                        <p className="description">I do Search Engine Optimization for any website and set up Google Search Console and Analytics.</p>
                                    </div>   
                                </Tab>
                            </TabList>
                        </div>
                    </div>
                    <div className="order-1 order-lg-2 col-lg-7 col-md-12 col-sm-12 col-12">
                        <div className="advance-tab-content advance-tab-content-1">
                            <TabPanel>
                                <div className="tab-content">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img src="./images/tab/tabs-02.jpg" alt="Corporate Images" />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            
                            <TabPanel>
                                <div className="tab-content">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img src="./images/tab/tabs-03.jpg" alt="Corporate Images" />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="tab-content">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img src="./images/tab/tabs-04.jpg" alt="Corporate Images" />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </div>
                    </div>
                </div>
            </Tabs>
        </div>
        </ScrollAnimation>
    )
}
export default AdvanceTabOne;