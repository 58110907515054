import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';

const AccordionOne = ({customStyle}) => {
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} defaultActiveKey="0">
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                Will you help me choose a niche for my Shopify store?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>Yes, one of the most important things is choosing the right niche or product for your Shopify store so I am here to help with that.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                Will you create me a logo for my website?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>Yes, a stunning logo is included in the premium package!</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                Is my Shopify store ready to make sales after you finish it?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body>Yes it is. You will get a new store that ready to make sales. We'll complete all necessary work, including design, copywriting, set up apps, legal pages and so on.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                Is Shopify the best platform to sell on the internet?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>Shopify comes with all the features needed to build a successful and amazing Store to sell on the internet. Shopify is the best because it don't have security issues, stability issues and customer support is always watching your back in case you run in to any issue.</Card.Body>
                </Accordion.Collapse>
            </Card>
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="4">
                Shall I be able to Customize my website?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                    <Card.Body>Your website will be fully Customizable! You will be able to very easily add text & images to all pages with a click! You will be able to edit eCommerce products, their images, descriptions & prices and add unlimited pages and posts too. No programming skills required!</Card.Body>
                </Accordion.Collapse>
            </Card>
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="5">
                Will my website be WordPress SEO Friendly Website?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                    <Card.Body>Yes, the structure of your website will be SEO Friendly.</Card.Body>
                </Accordion.Collapse>
            </Card>
            
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="6">
                Can I contact you after you have completed my Shopify store?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="6">
                    <Card.Body>100% Yes! I provide Lifetime customer support so you can contact me whenever you need help with anything.</Card.Body>
                </Accordion.Collapse>
            </Card>

        </Accordion>
    )
}
export default AccordionOne

