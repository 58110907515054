import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const testimonialData = [
    {
        image: 'testimonial-01',
        name: 'Max Lechanteur',
        designation: 'Owner of Easy Clothes',
        location: 'Canada', 
        description: '“Great developer! Ashraful is fast, he completed a project with a tight timeline and the website update came out perfect. He is easy to communicate with and very helpful. A great developer and I would 10/10 recommend him if you need an honest, hardworking and expert developer. We will be hiring him again for all our dev needs!„',
        url: 'https://www.linkedin.com/posts/ashraful41_see-how-i-can-get-your-project-done-on-upwork-activity-7078799749411176449-l0LM?utm_source=share&utm_medium=member_desktop',
    },
    {
        image: 'testimonial-02',
        name: 'Symone Simon',
        designation: 'Owner of MOTM',
        location: 'United State', 
        description: '“Ashraful Alam was extremely helpful with all aspects of the project. He is skilled and very patient. Ashraful made sure that my project was completed to align my vision while adding his professional input. My team enjoyed working with him and will be working with him in the future as our business grows.„',
        url: 'https://www.linkedin.com/posts/ashraful41_see-how-i-can-get-your-project-done-on-upwork-activity-6877293096355405824-cKlP?utm_source=share&utm_medium=member_desktop',
   
    },
    {
        image: 'testimonial-03',
        name: 'Giles Jackson',
        designation: 'Accounts Manager',
        location: 'Poland', 
        description: '“Ashraful did an amazing job in migrating our website. He is very knowledgeable and managed to integrate all of the necessary 3rd party apps while increasing the speed of our site. His communication was swift and consistent. We highly recommend him for your next project and look forward to working with him in the future.„',
        url: 'https://www.linkedin.com/posts/ashraful41_see-how-i-can-get-your-project-done-on-upwork-activity-6917897005323223040-XJiS?utm_source=share&utm_medium=member_desktop',
   
    },
    
]


const TestimonialOne = ({column , teamStyle}) => {
    return (
        <div className="row row--15">
            {testimonialData.map((data, index) => (
                <div className={`${column}`} key={index}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`rn-box-card ${teamStyle}`}>
                            <div className="inner">
                                <figure className="thumbnail">
                                    <img src={`./images/testimonial/${data.image}.jpg`} alt="Corporate React Template" />
                                </figure>
                                <figcaption className="content">
                                    <p className="description">{data.description}</p>
                                    <h2 className="title">{data.name}</h2>
                                    <h6 className="subtitle theme-gradient">{data.designation}</h6>
                                    <div className='button-group pt--20'><a className="btn-read-more" href={data.url} target='_blacnk'><span>Read This Review</span></a></div>
                                    
                                </figcaption>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}

export default TestimonialOne;
