import React from 'react';
import { FiArrowRight } from "react-icons/fi";
const callToActionData = {
    title: "About us",
    subtitle: "Why settle for an average e-commerce website that fails to generate sales? Collaborate with us and witness how we enhance your online brand, amplify your conversion rates, and grow your revenue. Join forces with us to develop a website that truly revolutionizes the success of your e-commerce business.",
    btnText: "Click to Hire",
}

const CalltoActionEight = () => {
    return (
        <div className="rn-callto-action rn-call-to-action style-8 content-wrapper">
            <div className="container">
                <div className="row row--0 align-items-center ">
                    <div className="col-lg-12">
                        <div className="inner">
                            <div className="content text-center">
                                <h2 className="title">{callToActionData.title}</h2>
                                <h6 className="subtitle">{callToActionData.subtitle}</h6>
                                <div className="call-to-btn text-center mt--30">
                                    <a className="btn-default btn-icon" target="_blank" rel="noreferrer" href="https://themeforest.net/checkout/from_item/33571911?license=regular">{callToActionData.btnText} <i className="icon"><FiArrowRight /></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalltoActionEight;