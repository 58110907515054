import React from 'react';
function App() {
  return (
    <div className="App">
      <iframe
        title="CodeFixd"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14501.390452634621!2d89.93240803061974!3d24.68057449092362!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39fd88e58bb6a739%3A0xb296f7a43d24cbe5!2sDhanbari!5e0!3m2!1sen!2sbd!4v1688921509588!5m2!1sen!2sbd"
        width="600"
        height="550"
        style={{ border: "5" }}
        allowfullscreen=""
        loading="lazy"
      ></iframe>
    </div>
  );
}

export default App;