import React from 'react';
import { FiMonitor, FiEdit, FiFilter } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: <FiMonitor />,
        title: 'Web Development',
        description: 'I build stunning Website for your business with 100% satisfaction, like WordPress, Shopify and CMS.'
    },
    {
        icon: <FiEdit />,
        title: 'Graphic Design',
        description: 'I build any type of Graphic Designs, like Logo Design, Banner Design, Ecommerce Photo Editng'
    },
    {
        icon: <FiFilter />,
        title: 'Search Engine Optimization',
        description: 'I do Search Engine Optimization for any website and set up Google Search Console and Analytics.'
    },
]

const ServiceOne = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h5 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h5>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceOne;