import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import ContactOne from "./ContactOne";

const Contact = () => {
    return (
        <>
            <SEO title="Contact || CodeFixd" />
            <Layout>
                <div className="main-content">
                    {/* Start Contact Area  */}
                    <div className="rwt-contact-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--40 text-center">
                                    <h1>My Contact Address Here.</h1>
                                    <p>To contact me by email, please complete the form below and I will respond as soon as possible.</p>
                                    
                                </div>
                            </div>
                            <ContactOne />
                        </div>
                    </div>
                    {/* End Contact Area  */}
                </div>
            </Layout>
        </>
    )
}
export default Contact;