import React from 'react';
import { FiArrowRight } from "react-icons/fi";
import Logo from "../../elements/logo/Logo";
import { FiFacebook, FiTwitter, FiLinkedin } from "react-icons/fi";
import { BsWhatsapp } from "react-icons/bs";
const CopyrightTwo = () => {
    return (

        <div className="copyright-area copyright-style-one variation-two">
            <div className="container">
                <div className="row align-items-center reorder">
                    <div className="col-lg-4 col-md-7 col-sm-12 col-12 ord1">
                    <Logo 
                                    image={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
                                    image2={`${process.env.PUBLIC_URL}/images/logo/logo-dark.png`}
                                />
                    </div>
                    <div className="col-lg-4 col-md-5 mt_sm--40 ord2">
                        <div className="copyright-center text-center">
                            <h3 className="justify-content-center"> Let’s Talk?</h3>
                        </div>

                         
                            <div className="container">
                                <div className="row">
                                <div className="col-lg-12">
                                    <ul className="social-icon social-default transparent-with-border">
                                        <li><a href="https://www.facebook.com/codefixd1" target='_balnk'><FiFacebook /></a></li>
                                        <li><a href="https://twitter.com/codefixd" target='_balnk'><FiTwitter /></a></li>
                                        <li><a href="https://wa.me/message/T2333JUB6NI2G1" target='_balnk'><BsWhatsapp /></a></li>
                                        <li><a href="https://www.linkedin.com/in/ashraful41/" target='_balnk'><FiLinkedin /></a></li>
                                    </ul>
                                </div>
                            </div>
                        
                     </div>

                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-12 mt_md--20 mt_sm--20 ord3">
                        <div className="copyright-right text-center">
                        <div className="call-to-btn text-start mt_sm--20 text-md-end">
                                <a className="btn-default btn-icon" rel="noreferrer" href="/contact"> LET'S CHAT<i className="icon"><FiArrowRight /></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                
                

            </div>

            <div className="container text-center">


            <ul className="ft-menu link-hover justify-content-center">
                <li><a href="#h">Privacy Policy</a></li>
                <li><a href="#h">Terms And Condition</a></li>
                <li><a href="/contact">Contact Us</a></li>
            </ul>
        <div className="justify-content-center">
            <p className="copyright-text">Copyright © {new Date().getFullYear()} CodeFixd</p>
        </div>

</div>

        </div>
    )
}
export default CopyrightTwo;